/* global screen, console */

import * as doc from "../Common/docElementsSite";

export async function adaptToScreen(loginStatus?) {
  console.log("adaptToScreen:", screen.width, loginStatus);
  if (screen.width < 680) {
    doc.navigation.style.display = "none";
    doc.headerTitle.style.display = "none";
    doc.cml.style.display = "none";
  } else {
    doc.navigation.style.display = "flex";
    doc.headerTitle.style.display = "flex";
  }
  if (screen.height < 680) {
    doc.contents.style.display = "none";
  } else {
    doc.contents.style.display = "flex";
  }
  if (loginStatus === undefined) {
    doc.navigation.style.display = "none";
    return;
  }
  if (loginStatus.status === "logged on") {
    doc.docLoading.style.display = "none";
    doc.nav_licmgmt.style.display = "block";
    doc.headerUserMenu.style.display = "flex";
  } else {
    doc.headerUserMenu.style.display = "none";
    doc.docLoading.style.display = "flex";
  }
}

export function adaptToScreen2(loginStatus?) {
  if (screen.width < 680) {
    //doc.navigation.style.display = "none";
    doc.headerTitle.style.display = "none";
    doc.cml.style.display = "none";
  } else {
    //doc.navigation.style.display = "flex";
    doc.headerTitle.style.display = "flex";
  }
  if (screen.height < 680) {
    //doc.contents.style.display = "none";
  } else {
    //doc.contents.style.display = "flex";
  }
}
