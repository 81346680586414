// eslint-disable-next-line no-redeclare, @typescript-eslint/no-unused-vars
/* global document, HTMLSelectElement, HTMLOptionElement, HTMLAnchorElement */

import {
  provideFluentDesignSystem,
  fluentButton,
  fluentProgressRing,
  fluentSwitch,
  fluentOption,
  fluentSelect,
} from "@fluentui/web-components";

provideFluentDesignSystem().register(
  fluentButton(),
  fluentProgressRing(),
  fluentSwitch(),
  fluentOption(),
  fluentSelect()
);

export const docTable = document.getElementById("table-container");
export const docMessage = document.getElementById("message");
export const docAppsource = document.getElementById("appsource") as HTMLAnchorElement;
export const divSwitches = document.getElementById("switches");
export const divStats = document.getElementById("stats2");
export const divUsers = document.getElementById("table-container");
export const docHeader = document.getElementById("header");
export const headerRight = document.getElementById("header-right");
export const headerLogonButton = document.getElementById("header-logon-button");
export const headerUserMenu = document.getElementById("user-menu") as HTMLOptionElement;
export const headerTitle = document.getElementById("header_title");
export const docFooter = document.getElementById("footer");
export const docLoading = document.getElementById("loading");

export const docLoginSection = document.getElementById("login-section");
export const docLoginBtn = document.getElementById("login-button");
export const docWelcomeMessage = document.getElementById("welcome-message");
export const docWelcomeMessage2 = document.getElementById("welcome-message2");
export const docSelectUser = document.getElementById("select-user");
export const docUserList: HTMLSelectElement = document.getElementById("user-list") as HTMLSelectElement;
export const docLicMgmtBtn = document.getElementById("licencemgmt-button");
export const docUsrMgmtBtn = document.getElementById("usermgmt-button");
export const cmlButton = document.getElementById("cml-button");
export const contents = document.getElementById("contents");
export const navDownloads = document.getElementById("nav_downloads");
export const tocDownloads = document.getElementById("toc_downloads");
export const body = document.getElementById("body");
//export let docBuyButton: typeof fluentButton;// = document.getElementById("licencebuy-button");
export const docBuySection = document.getElementById("buysection");
export const LicenceMessage = document.getElementById("licence-message");
export const eula = document.getElementById("eula");
export const eula_section = document.getElementById("eula-section");
export const buynow = document.getElementById("buynow");
export const main = document.getElementById("main");
export const paymentMethods = document.getElementById("payment-methods");
export const navigation = document.getElementById("navigation");
export const nav_home = document.getElementById("nav_home");
export const nav_licmgmt = document.getElementById("nav_licmgmt");
export const cml = document.getElementById("cml");

const docEmail = document.getElementById("email");

export function fixEmail(address: string) {
  docEmail.innerHTML = `<a href="mailto: ${address}">email us</a>`;
}
