// eslint-disable-next-line no-redeclare, @typescript-eslint/no-unused-vars
/* global console */

import * as docApp from "../taskpane/docElements";
import * as officeSettings from "../taskpane/office_settings";
//import * as docWeb from "../Website/Common/docElements";

export function licenceMessage(PDlicence: number) {
  switch (PDlicence) {
    case 0:
      return "No licence available";
    case 1:
      return "Standard licence";
    case 2:
      return "Premium licence";
    case 10:
      return "Trial licence";
    default:
      return "Licence error";
  }
}

export function setAppControls(myLicence: { PDlicence: number; isTrialPeriod: boolean }, isAddinActive: boolean) {
  docApp.Message.innerHTML = licenceMessage(myLicence.PDlicence);
  docApp.ManageLicence.innerHTML = "Purchase licence";
  docApp.Verify.style.display = "none";
  docApp.btnRetry.style.display = "flex";
  docApp.SideloadMessage.style.display = "flex";
  let PDlicence: number;
  if (myLicence.isTrialPeriod) {
    PDlicence = 10;
  } else {
    PDlicence = myLicence.PDlicence;
  }
  switch (PDlicence) {
    case 10:
      docApp.SwActivate.disabled = false;
      docApp.Verify.style.display = "flex";
      docApp.AppBody.style.display = "flex";
      docApp.SideloadMessage.style.display = "none";
      docApp.btnRetry.style.display = "none";
      docApp.SideloadMessage.style.display = "none";
      if (!isAddinActive) officeSettings.ribbonButtonStatus("ActivateButton", true);
      break;
    case 2:
      docApp.Verify.style.display = "flex";
      docApp.SideloadMessage.style.display = "none";
    // eslint-disable-next-line no-fallthrough
    case 1:
      docApp.ManageLicence.innerHTML = "Manage licence";
      docApp.SwActivate.disabled = false;
      docApp.AppBody.style.display = "flex";
      docApp.btnRetry.style.display = "none";
      docApp.SideloadMessage.style.display = "none";
      if (!isAddinActive) officeSettings.ribbonButtonStatus("ActivateButton", true);
      break;
    default:
      console.log("Licence error");
    // eslint-disable-next-line no-fallthrough
    case 0:
      officeSettings.ribbonButtonStatus("ActivateButton", false);
      docApp.btnRetry.style.display = "flex";
      docApp.AppBody.style.display = "none";
      break;
  }
  docApp.ManageLicence.style.display = "flex";
  return;
}
