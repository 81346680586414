// eslint-disable-next-line no-redeclare
/* global console, Office */

export function ribbonButtonStatus(buttonId: string, enabled: boolean) {
  Office.ribbon.requestUpdate({
    tabs: [
      {
        id: "TabData",
        groups: [
          {
            id: "powerDropdown",
            controls: [
              {
                id: buttonId,
                enabled: enabled,
              },
            ],
          },
        ],
      },
    ],
  });
}

export function activateOnOpen(bActivateOnOpen: boolean) {
  if (bActivateOnOpen) {
    Office.context.document.settings.set("activateOnOpen", true);
    Office.addin.setStartupBehavior(Office.StartupBehavior.load);
  } else {
    try {
      Office.addin.setStartupBehavior(Office.StartupBehavior.none);
      Office.context.document.settings.remove("activateOnOpen");
    } catch (err) {
      console.log("error removing activate on open");
    }
  }
  Office.context.document.settings.saveAsync();
}

export async function getSettings() {
  let response = await Office.context.document.settings.get("pdSettings");
  return response;
}

export function saveSettings(settings: any) {
  Office.context.document.settings.set("pdSettings", settings);
  Office.context.document.settings.saveAsync();
}
