// eslint-disable-next-line no-redeclare, @typescript-eslint/no-unused-vars
/* global window, console, location, document, URLSearchParams, screen */
/* global HTMLTableElement, HTMLButtonElement, HTMLTableRowElement, HTMLTableCellElement */

import * as msal_helper from "../Common/msal_helper";
import * as header_helper from "../Common/header_helper";
import * as api from "../Common/apiInterface";
import { licenceMessage } from "../../helpers/licence_helper";
import * as doc from "../Common/docElementsSite";
import * as helpers from "../Common/helpers";
import { adaptToScreen } from "../Common/visualization";
//import { fluentSelect, provideFluentDesignSystem } from "@fluentui/web-components";
import {
  provideFluentDesignSystem,
  fluentButton,
  fluentDataGrid,
  fluentDataGridRow,
  fluentDataGridCell,
  //DataGrid,
} from "@fluentui/web-components";
//provideFluentDesignSystem().register(fluentSelect());

provideFluentDesignSystem().register(fluentButton(), fluentDataGrid(), fluentDataGridRow(), fluentDataGridCell());
//const myMSALObj = new msal.PublicClientApplication(msalConfig);

window.addEventListener("DOMContentLoaded", async () => {
  console.log("DOM loaded-Root");
  let loginStatus = await msal_helper.loginStatus();
  adaptToScreen();
  //console.log(JSON.stringify(loginStatus.status));
  if (loginStatus.status === "logged on") {
    loginStatus = await msal_helper.loginStatus();
    loggedOn(loginStatus);
    screen.orientation.onchange = () => {
      adaptToScreen(loginStatus);
    };
  } else if (loginStatus.status === "selecting user") {
    console.log("user selection");
    selectUser(loginStatus.user);
    doc.docUserList.onchange = async () => {
      let loginStatus = await msal_helper.changeUser(doc.docUserList.value);
      loggedOn(loginStatus);
    };
  } else {
    doc.docLoginSection.style.display = "flex";
    doc.docLoginBtn.onclick = () => msal_helper.signIn();
    doc.docLoading.style.display = "none";
    doc.body.style.display = "none";
    doc.navigation.style.display = "none";
  }
});

async function loggedOn(loginStatus) {
  //console.log(JSON.stringify(myUsers));
  adaptToScreen(loginStatus);
  doc.nav_home.style.display = "block";
  doc.docHeader.style.display = "flex";
  doc.docFooter.style.display = "flex";
  doc.body.style.display = "flex";
  doc.docLoading.style.display = "flex";
  let myUsers = await api.getUsers();
  doc.docLoading.style.display = "none";
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has("t") && searchParams.has("s")) {
    const transactionResponse = {
      tid: searchParams.get("t"),
      s: searchParams.get("s"),
      eventId: searchParams.get("eventid"),
      eci: searchParams.get("eci"),
      lang: searchParams.get("lang"),
    };
    await buyLicence(transactionResponse);
    //console.log(response);
    window.location.href = "/";
  }
  if (myUsers.myUserData !== undefined) {
    doc.docWelcomeMessage.style.display = "flex";
    doc.docWelcomeMessage.innerHTML = `Welcome ${loginStatus.user.name}`;
    let strMessage1 = "You are signed in as ";
    let strMessage2 = "";
    let PDlicence: number;
    if (myUsers.myUserData.PDlicence >= myUsers.myUserData.PDlicence_seat) {
      PDlicence = myUsers.myUserData.PDlicence;
    } else {
      PDlicence = myUsers.myUserData.PDlicence_seat;
    }
    doc.LicenceMessage.innerHTML = "Active licence: " + licenceMessage(PDlicence);
    switch (myUsers.myUserData.isAdmin) {
      case 1:
        strMessage1 += "an Administrator";
        doc.docLicMgmtBtn.style.display = "flex";
        doc.nav_licmgmt.style.display = "block";
        doc.docUsrMgmtBtn.style.display = "none";
        break;
      default:
        doc.docUsrMgmtBtn.style.display = "none";
        doc.nav_licmgmt.style.display = "none";
        doc.docLicMgmtBtn.style.display = "none";
        strMessage1 += "a User";
        break;
    }
    switch (myUsers.myUserData.isLazy) {
      case 1:
        doc.cmlButton.style.display = "flex";
        break;
      default:
        doc.cmlButton.style.display = "none";
        break;
    }
    switch (myUsers.tenant.Plan) {
      case "Site":
      case "Seat":
        strMessage2 += `Your organization has a ${myUsers.tenant.Plan} based licence.`;
        break;
      default:
        strMessage2 += `No organization licence is available.`;
        doc.docUsrMgmtBtn.style.display = "none";
        doc.docLicMgmtBtn.style.display = "none";
        break;
    }
    doc.docWelcomeMessage2.innerHTML = `${strMessage1}. ${strMessage2}`;
    doc.docWelcomeMessage2.style.display = "flex";
    doc.docWelcomeMessage.hidden = false;
    //doc.docLicMgmtBtn.hidden = false;
    //
    doc.docSelectUser.hidden = true;
    //Right header section
    header_helper.header_right_show(loginStatus);
    //Buy Licence section
    //console.log(JSON.stringify(myUsers.eula));
    console.log(JSON.stringify(myUsers.offerings));
    if (myUsers.offerings === undefined || myUsers.offerings.error !== undefined) {
      doc.docBuySection.style.display = "none";
      return;
    }
    if (myUsers.offerings.length !== 0) {
      console.log(JSON.stringify(myUsers.offerings));
      //const buyOptions = await api.getBuyOptions();
      const buyOptions = myUsers;
      doc.docBuySection.style.display = "flex";
      doc.paymentMethods.style.display = "flex";
      doc.eula_section.style.display = "none";
      doc.eula.style.display = "flex";
      doc.eula.innerHTML = buyOptions.eula;
      //let table: DataGrid = document.createElement("fluent-data-grid") as DataGrid;
      //table.rowsData = buyOptions;
      //doc.docBuySection.appendChild(table);
      const extendTable = createActiveLicenceTable(buyOptions.offerings, PDlicence, myUsers.myUserData);
      if (extendTable !== undefined) doc.docBuySection.appendChild(extendTable);
      const addTable: HTMLTableElement = createBuyTable(buyOptions);
      if (addTable !== undefined) doc.docBuySection.appendChild(addTable);
    } else {
      doc.docBuySection.style.display = "none";
    }
  }
}

function selectUser(currentAccounts) {
  //console.log(JSON.stringify(currentAccounts));
  doc.docSelectUser.hidden = false;
  let strUsers: string = "";
  currentAccounts.forEach((element) => {
    //console.log(JSON.stringify(element.name));
    strUsers += `<fluent-option class="select-user" value="${element.homeAccountId}">${element.name} (${element.username})</fluent-option>`;
  });
  strUsers += `<fluent-option value="other">Select another user</fluent-option>`;
  //docUserList.title = "None";
  doc.docUserList.size = currentAccounts.length + 1;
  doc.docUserList.innerHTML = strUsers;
  return;
}

function createBuyButton(offering: {
  buyType: string;
  friendlyName: string;
  productCode: string;
  unitPrice: string;
  extendedPrice: string;
  currency: string;
}) {
  let button: HTMLButtonElement = document.createElement("fluent-button") as HTMLButtonElement;
  button.textContent = "Buy (" + offering.extendedPrice + helpers.getCurrencySymbol(offering.currency) + ")";
  button.setAttribute("appearance", "accent");
  button.value = JSON.stringify({
    productCode: offering.productCode,
    buyType: offering.buyType,
    extendedPrice: offering.extendedPrice,
    currency: offering.currency,
  });
  button.classList.add("cmlButton");
  button.onclick = buyLicence;
  return button;
}

function createAgreeButton(products: any) {
  let button: HTMLButtonElement = document.createElement("fluent-button") as HTMLButtonElement;
  button.textContent = "Agree";
  button.setAttribute("appearance", "accent");
  console.log(JSON.stringify({ action: "agreeEula", products }));
  button.value = JSON.stringify({ action: "agreeEula", products });
  button.classList.add("cmlButton");
  button.onclick = agreeEula;
  return button;
}

function createActiveLicenceTable(
  buyOptions: [
    {
      buyType: string;
      friendlyName: string;
      productCode: string;
      unitPrice: string;
      extendedPrice: string;
      currency: string;
    },
  ],
  PDlicence: number,
  myUserData: { lic_expiration: string }
) {
  let extendOption: {
    buyType: string;
    friendlyName: string;
    productCode: string;
    unitPrice: string;
    extendedPrice: string;
    currency: string;
  };
  buyOptions.forEach((buyOption) => {
    if (buyOption.buyType === "extend") {
      extendOption = buyOption;
      return;
    }
  });
  if (extendOption === undefined) return undefined;
  let table: HTMLTableElement = document.createElement("table") as HTMLTableElement;
  table.id = "active-table";
  table.className = "licence-table";
  let header: HTMLTableRowElement = document.createElement("tr") as HTMLTableRowElement;
  header.innerHTML = "<th>Active licence</th><th>Expiration</th><th>Extend by a year</th>";
  let expDate = new Date(Date.parse(myUserData.lic_expiration));
  let button: HTMLButtonElement = createBuyButton(extendOption);
  let buttonCell: HTMLTableCellElement = document.createElement("td") as HTMLTableCellElement;
  buttonCell.appendChild(button);
  buttonCell.className = "licence-table-button";
  let rowActive: HTMLTableRowElement = document.createElement("tr") as HTMLTableRowElement;
  rowActive.innerHTML = `<td>${licenceMessage(PDlicence)}</td><td>${expDate.toDateString()}</td>`;
  rowActive.appendChild(buttonCell);
  table.appendChild(header);
  table.appendChild(rowActive);
  return table;
}

function createBuyTable(buyOptions: {
  offerings: {
    buyType: string;
    friendlyName: string;
    productCode: string;
    unitPrice: string;
    extendedPrice: string;
    currency: string;
    term: string;
    qty: number;
  }[];
  eula: string;
}) {
  let table: HTMLTableElement = document.createElement("table") as HTMLTableElement;
  table.id = "buy-table";
  table.className = "licence-table";
  let header: HTMLTableRowElement = document.createElement("tr") as HTMLTableRowElement;
  header.innerHTML = "<th>Description</th><th>Cost</th><th>Term</th><th>Purchase</th>";
  table.appendChild(header);
  let rowActive: HTMLTableRowElement = document.createElement("tr") as HTMLTableRowElement;
  rowActive.innerHTML = `<td>Active licence: </td>`;
  buyOptions.offerings.forEach((offering) => {
    if (offering.buyType !== "extend") {
      let row: HTMLTableRowElement = document.createElement("tr") as HTMLTableRowElement;
      row.innerHTML = `<td>${offering.friendlyName}</td><td>${offering.extendedPrice}${helpers.getCurrencySymbol(
        offering.currency
      )}</td>
      <td>${offering.term}</td>`;
      let cell: HTMLTableCellElement = document.createElement("td") as HTMLTableCellElement;
      let button: HTMLButtonElement = createBuyButton(offering);
      cell.appendChild(button);
      row.appendChild(cell);
      table.appendChild(row);
    }
  });
  if (table.rows.length > 1) {
    return table;
  } else {
    return undefined;
  }
}

async function buyLicence(transactionResponse?) {
  console.log(JSON.stringify(transactionResponse));
  if (transactionResponse.s === undefined) {
    transactionResponse = undefined;
  }
  try {
    let products: {
      productCode: any;
      buyType: any;
      qty: number;
      discountPercent: number;
      extendedPrice: number;
      currency: string;
    }[];
    if (transactionResponse === undefined) {
      const product = JSON.parse(this.value);
      //const discountPercent = 0.1;
      products = [
        {
          productCode: product.productCode,
          buyType: product.buyType,
          qty: product.qty,
          discountPercent: product.discountPercent,
          extendedPrice: product.extendedPrice,
          currency: product.currency,
        },
      ];
      console.log("buy: " + JSON.stringify(products));
      doc.docBuySection.style.display = "none";
      doc.buynow.appendChild(createAgreeButton(products));
      doc.eula_section.style.display = "flex";
      doc.buynow.style.display = "flex";
      return;
    } else {
      await api.buyLicence(products, transactionResponse);
    }
  } catch (err) {
    console.warn(JSON.stringify(err));
  }
}

async function agreeEula() {
  let buttonValue = JSON.parse(this.value);
  if (buttonValue.action === "agreeEula") {
    doc.eula_section.style.display = "none";
    this.textContent =
      "Buy now (" +
      buttonValue.products[0].extendedPrice +
      helpers.getCurrencySymbol(buttonValue.products[0].currency) +
      ")";
    buttonValue.action = "buyNow";
    this.value = JSON.stringify(buttonValue);
    return;
  }
  if (buttonValue.action === "buyNow") {
    doc.main.style.display = "none";
    doc.buynow.style.display = "none";
    doc.docFooter.style.display = "none";
    doc.docLoading.style.display = "flex";
  }
  try {
    let products = buttonValue.products;
    console.log("buy: " + JSON.stringify(products));
    doc.eula_section.style.display = "none";
    //let result;
    let result = await api.buyLicence(products, undefined);
    if (result.status === "success") {
      console.log("Buy license result: " + result.status);
      console.log(JSON.stringify(result));
      window.location.href = "/";
    } else if (result.status === "redirect") {
      console.log(JSON.stringify(result));
      window.location.href = result.redirectUrl;
    } else {
      console.log(result.error);
      console.log("API request failed");
    }
  } catch (err) {
    console.warn(JSON.stringify(err));
  }
}
