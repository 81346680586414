/* global console */
import {
  PublicClientApplication,
  AuthenticationResult,
  AccountInfo,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { msalConfig, loginRequest } from "./authConfig";
//export const myUser = { name: "", email: "" };

let myMSALObj = new PublicClientApplication(msalConfig);

export let homeAccountId: string = null; // The home account ID of the user that signs in.
let result: AuthenticationResult = null;

export async function loginStatus() {
  if (homeAccountId !== null) {
    try {
      myMSALObj.setActiveAccount(myMSALObj.getAccount({ homeAccountId: homeAccountId }));
    } catch (err) {
      console.warn(err);
    }
  }
  await myMSALObj.initialize();
  const response = await myMSALObj.handleRedirectPromise();
  const loginStatus = await handleResponse(response, false, false);
  if (loginStatus.user !== undefined) {
    let accountInfo: AccountInfo = loginStatus.user as AccountInfo;
    //console.log("Setting homeAccountId");
    homeAccountId = accountInfo.homeAccountId;
  }
  return loginStatus;
}

export async function getAccessToken() {
  //console.log(JSON.stringify(loginRequest));
  if (homeAccountId !== null) {
    try {
      myMSALObj.setActiveAccount(myMSALObj.getAccount({ homeAccountId: homeAccountId }));
    } catch (err) {
      console.warn(err);
    }
    try {
      result = await myMSALObj.acquireTokenSilent(loginRequest);
      //console.log("***Silent success: " + JSON.stringify(result));
    } catch (err) {
      console.warn("***Silent logon failed: " + JSON.stringify(err));
    }
    if (result !== null && result.accessToken !== null) {
      //console.log(JSON.stringify(result.scopes));
      return result.accessToken;
    } else return null;
  }
}

async function handleResponse(
  response: AuthenticationResult,
  loginRedirect: boolean = true,
  changeUser: boolean = false
) {
  //console.log("handleresponse:" + loginRedirect + changeUser + JSON.stringify(response));
  if (changeUser) {
    response = null;
  }
  if (response !== null) {
    console.log("handleresponse user not null");
    return { status: "logged on", user: response.account };
  } else {
    const currentAccounts = myMSALObj.getAllAccounts();
    //console.log(currentAccounts.length + " " + changeUser);
    if (currentAccounts.length === 0 || changeUser) {
      if (loginRedirect) {
        loginRequest.prompt = "select_account";
        myMSALObj.loginRedirect(loginRequest);
      } else {
        return { status: "logged off" };
      }
    } else if (currentAccounts.length > 1) {
      // Add your account choosing logic here
      console.warn("Multiple accounts detected.");
      let activeUser = myMSALObj.getActiveAccount();
      let myToken;
      try {
        myToken = await myMSALObj.acquireTokenSilent(loginRequest);
      } catch (err) {
        myMSALObj.clearCache();
        return { status: "logged off" };
      }
      console.log("Activeuser set: " + myToken); // + JSON.stringify(activeUser));
      if (activeUser === null) {
        //console.log(JSON.stringify(myMSALObj.getActiveAccount()));
        return { status: "selecting user", user: currentAccounts };
      } else {
        //console.log("status: logged on, user: " + activeUser);
        homeAccountId = activeUser.homeAccountId;
        return { status: "logged on", user: activeUser };
      }
    } else if (currentAccounts.length === 1) {
      homeAccountId = currentAccounts[0].homeAccountId;
      return { status: "logged on", user: currentAccounts[0] };
    }
  }
}

export async function signIn(sso: boolean = false, redirectPage?: string) {
  if (redirectPage !== undefined) {
    let newMsalConfig = msalConfig;
    newMsalConfig.auth.redirectUri += redirectPage;
    myMSALObj = new PublicClientApplication(newMsalConfig);
  }
  await myMSALObj.initialize();
  //console.log(JSON.stringify(loginRequest));
  if (!sso) {
    const response = await myMSALObj.handleRedirectPromise();
    await handleResponse(response);
  } else {
    try {
      const response = await myMSALObj.ssoSilent(loginRequest);
      return response;
    } catch (err) {
      if (err instanceof InteractionRequiredAuthError) {
        const response = await myMSALObj.handleRedirectPromise();
        await handleResponse(response);
        return response;
      } else {
        console.log("Unknown error occurred");
        return undefined;
      }
    }
  }
}

export async function signOut() {
  /**
   * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */
  //console.log("Logout " + myMSALObj.getActiveAccount().username);
  const logoutRequest = {
    account: myMSALObj.getActiveAccount(),
    postLogoutRedirectUri: msalConfig.auth.redirectUri,
  };
  try {
    await myMSALObj.logoutRedirect(logoutRequest);
  } catch (err) {
    console.warn(err);
  }
}

export async function changeUser(selectedUser = null) {
  //console.log("changeUser: " + selectedUser);
  await myMSALObj.initialize();
  if (selectedUser === null || selectedUser == "other") {
    myMSALObj.setActiveAccount(null);
    const response = await myMSALObj.handleRedirectPromise();
    await handleResponse(response, true, true);
  } else {
    //console.log("User Selected: " + selectedUser);
    myMSALObj.setActiveAccount(myMSALObj.getAccount({ homeAccountId: selectedUser }));
    //console.log(myMSALObj.getActiveAccount());
    const response = await myMSALObj.handleRedirectPromise();
    let loginStatus = await handleResponse(response, false, false);
    return loginStatus;
  }
}

export function unsetHomeAccount() {
  homeAccountId = null;
  myMSALObj.setActiveAccount(null);
}
